import React, { useEffect, useRef, useState } from 'react'
import * as rawStyles from './styles.module.scss'
import Card from '../../components/card'
import { scroll, animate } from '@motionone/dom'
import Wrapper from '../../basics/wrapper'
import { FeaturesModuleProps } from './types'
import { BREAKPOINTS } from '../../../constants/breakpoints'

const styles: any = rawStyles

const CARD_POINT_SCROLL_DOWN = 17
const CARD_POINT_SCROLL_UP = 27

const FeaturesModule: React.FC<FeaturesModuleProps> = ({ cards = [] }) => {
    const cardsWrapperRef = useRef<HTMLUListElement>(null)
    const cardsRefs = useRef<(HTMLLIElement | null)[]>([])
    const [initialPosition, setInitialPosition] = useState(CARD_POINT_SCROLL_DOWN)

    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(typeof window !== 'undefined')
    }, [])

    useEffect(() => {
        if (!isClient) return

        const $cardsWrapper = cardsWrapperRef.current
        const $cards = cardsRefs.current
        let lastScrollY = window.scrollY

        const applyScrollAnimation = () => {
            if (window.innerWidth >= BREAKPOINTS.lg && $cardsWrapper && $cards.length > 0) {
                const numCards = $cards.length

                $cards.forEach(($card, index0) => {
                    const index = index0
                    const reverseIndex0 = numCards - index

                    if ($card instanceof HTMLLIElement) {
                        $card.style.paddingTop = `calc(${index} * var(--card-top-offset))`
                        scroll(
                            animate($card, {
                                transform: [
                                    `translateY(0px)`,
                                    `translateY(-${reverseIndex0 * 32}px)`,
                                ],
                            }),
                            {
                                target: $cardsWrapper,
                                offset: [
                                    `${(index0 / numCards) * 100}%`,
                                    `${(index / numCards) * 100}%`,
                                ],
                            },
                        )
                    }
                })
            } else {
                // Reset styles for smaller screens
                $cards.forEach(($card) => {
                    if ($card) {
                        $card.style.transition = 'none'
                        $card.style.transform = 'none'
                        $card.style.paddingTop = '0'
                    }
                })
            }
        }

        const handleScroll = () => {
            if (window.innerWidth >= BREAKPOINTS.lg) {
                const currentScrollY = window.scrollY
                const scrollingDown = currentScrollY > lastScrollY

                window.requestAnimationFrame(() => {
                    if (scrollingDown) {
                        setInitialPosition(CARD_POINT_SCROLL_DOWN)
                    } else {
                        setInitialPosition(CARD_POINT_SCROLL_UP)
                    }
                })

                lastScrollY = currentScrollY
            }
        }

        applyScrollAnimation()
        window.addEventListener('resize', applyScrollAnimation)
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('resize', applyScrollAnimation)
            window.removeEventListener('scroll', handleScroll)
        }
    }, [isClient])

    return (
        <div className={styles.m_features}>
            <Wrapper type='modal'>
                <ul id='cards' className={styles.cards} ref={cardsWrapperRef}>
                    {cards.map((card, index) => (
                        <li
                            key={index}
                            className='card'
                            id={`card_${index}`}
                            ref={(el) => (cardsRefs.current[index] = el)}
                            style={{
                                top:
                                    isClient && window.innerWidth >= BREAKPOINTS.lg
                                        ? `${initialPosition}vh`
                                        : 'initial',
                                transition:
                                    isClient && window.innerWidth >= BREAKPOINTS.lg
                                        ? 'top 0.5s ease'
                                        : 'none',
                            }}>
                            <Card {...card} isLast={index === 3} />
                        </li>
                    ))}
                </ul>
            </Wrapper>
        </div>
    )
}

export default FeaturesModule
