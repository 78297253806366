/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import * as myStyles from './styles.module.scss'
import HeroModule from '../../lib/components/modules/hero'
import FixedTitle from '../../lib/components/components/fixedTitle'
import Features from '../../lib/components/modules/features'
import Testimonials from '../../lib/components/modules/testimonials'
import {
    heroProps,
    testimonialsIntro,
    testimonials,
    featuresTitle,
    preFooterTitle,
    graphicsAndLogos,
    cardsData,
    ReviewsPrefooter,
    cardsDataPrefooter,
} from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import GraphicLogos from '../../lib/components/modules/graphic-logos'
import useResponsive from '../../lib/hooks/useResponsive'
import { BREAKPOINTS } from '../../lib/constants/breakpoints'

import heroBgDesktop from '../../../resources/images/hanzo/hero/hero_bg_desktop.mp4'
import heroBgMobile from '../../../resources/images/hanzo/hero/hero_bg_mobile.mp4'

const HomePage = () => {
    const styles: any = myStyles
    const isMobile = useResponsive({ threshold: BREAKPOINTS.md })

    return (
        <div className={styles.home_page}>
            <div className={styles.content}>
                <HeroModule
                    {...heroProps}
                    background={{
                        video: {
                            src: isMobile ? heroBgMobile : heroBgDesktop,
                        },
                    }}
                />

                <FixedTitle title={featuresTitle} />

                <div className={styles.bg_grey_black}>
                    <Features cards={cardsData} />
                </div>

                <Testimonials intro={testimonialsIntro} testimonials={testimonials} />

                <div className={styles.bg_grey_black}>
                    <GraphicLogos {...graphicsAndLogos} />
                </div>

                <div className={styles.bg_grey_20}>
                    <PreFooter
                        title={preFooterTitle}
                        reviews={ReviewsPrefooter}
                        cardsData={cardsDataPrefooter}
                    />
                </div>
            </div>
        </div>
    )
}

export default HomePage
