import React from 'react'
import type { CardProps } from '../../lib/components/components/card/types'
import {
    AdidasLogo,
    DaimlerLogo,
    GeneraliLogo,
    KafkaLogo,
    Aiven,
    Ball,
    Confluent,
    Aws,
    Strimzi,
    Cloudera,
    GoogleCloud,
    IbmCloud,
    Instaclustr,
    RedHat,
    RedPanda,
    WarpStream,
    DownloadBigIcon,
    DemoIcon,
    ArityLogo,
    KandjiLogo,
    HealthFirstLogo,
} from '../../lib/components/basics/icons'

import governVideoFeature from '../../../resources/images/hanzo/features/01_govern.mp4'
import exploreVideoFeature from '../../../resources/images/hanzo/features/02_discovery.mp4'
import processVideoFeature from '../../../resources/images/hanzo/features/03_process.mp4'
import integrateVideoFeature from '../../../resources/images/hanzo/features/04_integrate.mp4'
import governVideoFeatureOptimized from '../../../resources/images/hanzo/features/01_govern.webm'
import exploreVideoFeatureOptimized from '../../../resources/images/hanzo/features/02_discovery.webm'
import processVideoFeatureOptimized from '../../../resources/images/hanzo/features/03_process.webm'
import integrateVideoFeatureOptimized from '../../../resources/images/hanzo/features/04_integrate.webm'

// Image for graphicsAndLogos
import graphicAndLogosImage from '../../../resources/images/hanzo/product/architecture-diagram.webp'

// Testimonials users
import adminUserImg from '../../../resources/images/hanzo/testimonials/admin.webp'
import developerUserImg from '../../../resources/images/hanzo/testimonials/developer.webp'
import leaderUserImg from '../../../resources/images/hanzo/testimonials/leader.webp'

// HERO
export const heroProps = {
    title: 'Autonomy in data streaming.',
    description: (
        <p>
            Lenses is the Developer Experience tooling for enterprises to work with every
            <b> Apache Kafka</b>, in one place.
        </p>
    ),
    info: 'Compatible with the',
    icon: <KafkaLogo />,
    primaryCtaLink: '/apache-kafka-docker',
    primaryCtaLabel: 'Download free',
    secondaryCtaLink: '/request-demo',
    secondaryCtaLabel: 'Get a demo',
    intro: 'Giving autonomy to',
    logos: [
        <AdidasLogo key='adidas' />,
        <ArityLogo key='ArityLogo' />,
        <DaimlerLogo key='daimler-2' />,
        <GeneraliLogo key='GeneraliLogo' />,
        <HealthFirstLogo key='HealthFirst' />,
        <KandjiLogo key='Kandji' />,
    ],
}

// TESTIMONIALS
export const testimonialsIntro = 'Lenses is loved by'
export const testimonials = [
    {
        title: 'Developers',
        userIconSrc: developerUserImg,
        name: 'Frank Hart',
        job: 'Trading Systems Lead, Flow Traders',
        quote: (
            <div>
                “Lenses has been invaluable for our engineering productivity. Lenses for multi-Kafka
                allows us to further scale our streaming initiatives.”
            </div>
        ),
        buttonLabel: 'Learn more',
        buttonLink: '/customers',
    },
    {
        title: 'Platform admins',
        userIconSrc: adminUserImg,
        name: 'Phil Falkenholm',
        job: 'Director of Infrastructure and Security, Arity',
        quote: (
            <div>
                “Whether it’s migration, security, or a new functionality that can improve our
                business, <b>Lenses.io</b> and AWS are always there for us. They&apos;re two very
                good partners.”
            </div>
        ),
        buttonLabel: 'Learn more',
        buttonLink: '/customers',
    },
    {
        title: 'Technology leaders',
        userIconSrc: leaderUserImg,
        name: 'Ella Vidra',
        job: 'VP of IT Ops & Engineering, Playtika',
        quote: (
            <div>
                “<b>Lenses.io</b> has been a great choice for us, enabling us to accelerate the
                delivery of new value to the market.”
            </div>
        ),
        buttonLabel: 'Learn more',
        buttonLink: '/customers',
    },
]

// FEATURES
export const featuresTitle = 'With Lenses for Kafka:'

// PREFOOTER
export const preFooterTitle = 'Try Lenses for yourself'

//GRAPHICS AND LOGOS

export const graphicsAndLogos = {
    title: 'One operating layer, all your Kafka clusters',
    graphicSrc: graphicAndLogosImage, // <--- here
    graphicAltText: 'architecture diagram',
    logos: [
        <Aws key='aws' />,
        <RedPanda key='redPanda' />,
        <Cloudera key='cloudera' />,
        <Confluent key='confluence' />,
        <RedHat key='redhat' />,
        <Strimzi key='strim' />,
        <WarpStream key='wrapStream' />,
        <IbmCloud key='ibmCloud' />,
        <Ball key='ball' />,
        <GoogleCloud key='googleCloud' />,
        <Aiven key='aiven' />,
        <Instaclustr key='instaclustr' />,
    ],
}

// FEATURES

// Data for the cards
export const cardsData: CardProps[] = [
    {
        title: 'One place to rule all permissions',
        tag: 'Govern',
        firstParagraph: (
            <>
                Managing permissions across multiple environments is holding your team back.
                <br />
                <br />
                Traditional controls don’t give developers the flexible self-service they need.
                While distributed infrastructure means scattered, imprecise access levels.
            </>
        ),
        secondParagraph:
            'Safely delegate control, so developers have the autonomy to access the right data.',
        buttonText: 'Learn more',
        // imgSrc: 'https://codyhouse.co/demo-tutorials/stacking-cards/assets/img/img-2.jpg',
        altText: 'Identity and Access Management',
        href: '/product',
        video: {
            src: governVideoFeature,
            srcOptimized: governVideoFeatureOptimized,
        },
    },
    {
        title: 'Get all eyes on your streaming data',
        tag: 'Discover',
        firstParagraph:
            'When hundreds of engineers need to find and explore data across billions of streams – countless hours are wasted.',
        secondParagraph:
            'Discover topics, explore billions of streaming records in seconds, and troubleshoot messages in minutes with SQL, to get up to speed with your data fast.',
        buttonText: 'Learn more',
        // imgSrc: 'https://codyhouse.co/demo-tutorials/stacking-cards/assets/img/img-2.jpg',
        altText: 'Image description',
        href: '/product',
        video: {
            src: exploreVideoFeature,
            srcOptimized: exploreVideoFeatureOptimized,
        },
    },
    {
        title: 'Let anyone get to grips with data streaming',
        tag: 'Process',
        firstParagraph:
            'To prepare streaming data for consumer applications, you need to build complex data processors, or wait for a central team to do it for you.',
        secondParagraph:
            'Open up streaming to more developers, by building stream processing applications in a common language – SQL.',
        buttonText: 'Learn more',
        // imgSrc: 'https://codyhouse.co/demo-tutorials/stacking-cards/assets/img/img-3.jpg',
        altText: 'Image description',
        href: '/product',
        video: {
            src: processVideoFeature,
            srcOptimized: processVideoFeatureOptimized,
        },
    },
    {
        title: 'Move streams, make waves',
        tag: 'Integrate',
        firstParagraph:
            'Integrating data streams into your applications should be straightforward, but often it depends on other teams.',
        secondParagraph:
            'Don’t wait; do it yourself. Connect your data sources, wrapped in a security blanket with built-in monitoring and alerting.',
        buttonText: 'Learn more',
        // imgSrc: 'https://codyhouse.co/demo-tutorials/stacking-cards/assets/img/img-2.jpg',
        altText: 'Lenses Kafka Connectors',
        href: '/product',
        video: {
            src: integrateVideoFeature,
            srcOptimized: integrateVideoFeatureOptimized,
        },
    },
]

// FLEXIBLE MODULE
export const flexibleImage = {
    imgSrc: 'https://codyhouse.co/demo-tutorials/stacking-cards/assets/img/img-2.jpg',
    altText: 'Image description',
}

export const flexible1TextBlocks = [
    {
        pretitle: 'Phalesus',
        title: 'Block 1',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In placerat consequat dictum. Maecenas non risus lobortis lectus pretium hendrerit eget eget eros. Donec a lectus ante. Phasellus dictum orci non orci ornare, eget suscipit massa facilisis. Phasellus tempus non urna a pulvinar. Aliquam blandit, metus.',
        ctas: [
            {
                text: 'Read More',
                href: '/read-more-1',
                variant: 'primary-orange',
                onClick: () => {
                    console.log('click button')
                },
            },
            {
                text: 'Contact Us',
                href: '/contact-1',
                variant: 'secondary-light',
                onClick: () => {
                    console.log('click button')
                },
            },
        ],
    },
]

export const flexibleTextBlocks = [
    {
        pretitle: 'Phalesus',
        title: 'Block 1',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In placerat consequat dictum. Maecenas non risus lobortis lectus pretium hendrerit eget eget eros. Donec a lectus ante. Phasellus dictum orci non orci ornare, eget suscipit massa facilisis. Phasellus tempus non urna a pulvinar. Aliquam blandit, metus.',
        ctas: [
            {
                text: 'Read More',
                href: '/read-more-1',
                variant: 'primary-orange',
                onClick: () => {
                    console.log('click button')
                },
            },
            {
                text: 'Contact Us',
                href: '/contact-1',
                variant: 'secondary-light',
                onClick: () => {
                    console.log('click button')
                },
            },
        ],
    },
    {
        pretitle: 'Phalesus',
        title: 'Block 2',
        description: 'This is the description for block 2.',
        ctas: [
            {
                text: 'Learn More',
                href: '/learn-more-2',
                variant: 'primary-orange',
                onClick: () => {
                    console.log('click button')
                },
            },
            {
                text: 'Contact Us',
                href: '/contact-1',
                variant: 'secondary-light',
                onClick: () => {
                    console.log('click button')
                },
            },
        ],
    },
    {
        title: 'Block 3',
        description: 'This is the description for block 3.',
        ctas: [
            {
                text: 'Subscribe',
                href: '/subscribe',
                variant: 'primary-orange',
                onClick: () => {
                    console.log('click button')
                },
            },
        ],
    },
]

// PRE-FOOTER
export const ReviewsPrefooter = [
    {
        review: 'Lenses is amazing at providing insights into the Kafka topics',
        author: 'Alexandru-Florian A',
    },
    {
        review: 'The Lenses product is amazing and I am positive it has drastically increased our development times using Kafka.',
        author: 'Matthew G.',
    },
    {
        review: 'Beautiful, responsive UI for kafka overview and with lenses SQL for kafka topics browsing',
        author: 'Ivan V.',
    },
    {
        review: "Lenses provides a quick inside view of all your data flow. There's not much to dislike.",
        author: 'Kyriakos P.',
    },
    {
        review: 'Lenses is an intuitive tool that provides great observability and management features for our streaming',
        author: 'Anonymous',
    },
    {
        review: 'A very respectable add-on for enterprise-grade Kafka clusters!',
        author: 'Donny F.',
    },
    { review: 'Invaluable tool for anyone using Kafka', author: 'Michael G.' },
    {
        review: `Very complete tool to browse and understand what's happening inside your clusters`,
        author: 'George F.',
    },
]
/* eslint-disable no-unused-vars */
export const enum Type {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
}

export const cardsDataPrefooter = [
    {
        title: (
            <>
                20 minute
                <br />
                demo
            </>
        ),
        type: Type.Primary,
        description: [
            'Get a guided tour of Lenses',
            'Receive a trial key to install on your clusters',
            'Perfect for production and large-scale streaming deployments.',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demoIcon' />,
    },
    {
        title: 'Free Lenses',
        leadingText: 'Run the docker command to get Lenses Community Edition:',
        type: Type.Secondary,
        description: [
            'Apache Kafka Docker + Lenses 6.0',
            'Pre-loaded synthetic data',
            'Up to 2 users + environments',
        ],
        buttonText: 'Download',
        href: '/apache-kafka-docker',
        icon: <DownloadBigIcon key='downloadBigIcon' />,
    },
]
